import React, { useEffect, useState } from "react";
import {
  Select,
  Spin,
  TreeSelect,
  Form,
  DatePicker,
  Icon,
  Tooltip,
  Input
} from "antd";
import { observer } from "mobx-react";
import { debounce } from "throttle-debounce";
import Button from "../../../common/Button";
import createSearchString from "../../../helpers/createSearchString";
import getUrlParams from "../../../helpers/getUrlParams";
import { ReactComponent as iconSearch } from "../../../images/icons/icon-search.svg";
import AccessControl from "../../../models/AccessControl";
// import styles
import "./styles.less";
import "../../../styles/search-input.less";
import "../../../common/RangePicker/styles.less";

function StoryListFilters({
  loadingFilters,
  filters,
  form,
  filtersInitialValues,
  history: { location },
  isAbleToViewCompanies
}) {
  const [showResetButton, setShowResetButton] = useState(false);
  const [isAbleToCreateStory, setIsAbleToCreateStory] = useState(false);

  useEffect(() => {
    const filtersNamesArr = [
      "company",
      "brand",
      "platform",
      "category",
      "publishTypes",
      "contentType",
      "date",
      "status",
      "links",
      "searchCommon"
    ];
    setShowResetButton(false);
    Object.keys(filtersInitialValues).forEach(key => {
      if (
        filtersNamesArr.includes(key) &&
        filtersInitialValues[`${key}`].length
      ) {
        setShowResetButton(true);
      }
    });
  }, [filtersInitialValues, showResetButton]);

  useEffect(() => {
    async function checkPermission() {
      const isAbleToCreateNewStory = await AccessControl.can(
        "story:create-new-story"
      );
      setIsAbleToCreateStory(isAbleToCreateNewStory);
    }
    checkPermission();
  }, []);

  const { getFieldDecorator, setFieldsValue, getFieldValue } = form;

  const { RangePicker } = DatePicker;

  const { Option } = Select;

  const getFilteredByCompany = companyFilters => {
    const company = getFieldValue("company");
    if (!company?.length) {
      return companyFilters;
    }
    return companyFilters.filter(item => company.includes(item.company_id));
  };

  const resetFilters = () => {
    setFieldsValue({
      company: [],
      brand: [],
      platform: [],
      category: [],
      publishTypes: [],
      contentType: [],
      links: [],
      date: [],
      status: [],
      searchCommon: ""
    });
  };
  return (
    <React.Fragment>
      {loadingFilters && (
        <div className="filters-spinner">
          <Spin size="large" />
        </div>
      )}
      {!loadingFilters && filters.size && (
        <div className="story-filters-wrapper">
          <Form>
            <div className="story-filters">
              {/* Reset btn */}
              {showResetButton && (
                <Tooltip placement="top" title="Reset all filters">
                  <div className="reset-wrapper" onClick={resetFilters}>
                    <Icon className="reset-icon" type="reload" />
                    <span className="reset-text">Reset</span>
                  </div>
                </Tooltip>
              )}
              {/* Company */}
              {isAbleToViewCompanies && (
                <Form.Item>
                  {getFieldDecorator("company", {
                    initialValue: filtersInitialValues.company
                  })(
                    <Select
                      size="large"
                      dropdownMatchSelectWidth={false}
                      mode="multiple"
                      placeholder={filters.get("companies").name}
                      className="story-filters-company-select"
                      suffixIcon={<Icon type="caret-down" theme="filled" />}
                      showArrow
                    >
                      {filters
                        .get("companies")
                        .select_options.map(({ value, label }) => (
                          <Option value={value} key={value}>
                            {label}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              )}
              {/* Platform */}
              <Form.Item>
                {getFieldDecorator("brand", {
                  initialValue: filtersInitialValues.brand
                })(
                  <Select
                    size="large"
                    dropdownMatchSelectWidth={false}
                    mode="multiple"
                    placeholder="Platform"
                    className="story-filters-brand-select"
                    suffixIcon={<Icon type="caret-down" theme="filled" />}
                    showArrow
                  >
                    {getFilteredByCompany(
                      filters.get("brands").select_options
                    ).map(({ value, label }) => (
                      <Option value={value} key={value}>
                        {label}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              {/* Channel */}
              <Form.Item>
                {getFieldDecorator("platform", {
                  initialValue: filtersInitialValues.platform
                })(
                  <Select
                    size="large"
                    dropdownMatchSelectWidth={false}
                    mode="multiple"
                    placeholder={filters.get("platforms").name}
                    className="story-filters-content_type-select"
                    suffixIcon={<Icon type="caret-down" theme="filled" />}
                    showArrow
                  >
                    {filters
                      .get("platforms")
                      .select_options.map(({ value, label }) => (
                        <Option value={value} key={value}>
                          {label}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("category", {
                  initialValue: filtersInitialValues.category
                })(
                  <TreeSelect
                    className="tree-select"
                    dropdownClassName="filters-tree-select-dropdown"
                    treeData={getFilteredByCompany(
                      filters.get("categories").select_options
                    )}
                    size="large"
                    placeholder={filters.get("categories").name}
                    treeIcon
                    multiple
                  />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("publishTypes", {
                  initialValue: filtersInitialValues.publishTypes
                })(
                  <Select
                    size="large"
                    dropdownMatchSelectWidth={false}
                    mode="multiple"
                    placeholder={filters.get("publishTypes").name}
                    suffixIcon={<Icon type="caret-down" theme="filled" />}
                    showArrow
                    className="story-filters-content_type-select"
                  >
                    {getFilteredByCompany(
                      filters.get("publishTypes").select_options
                    ).map(({ value, label }) => (
                      <Option value={value} key={value}>
                        {label}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("contentType", {
                  initialValue: filtersInitialValues.contentType
                })(
                  <Select
                    size="large"
                    dropdownMatchSelectWidth={false}
                    mode="multiple"
                    placeholder={filters.get("content_types").name}
                    suffixIcon={<Icon type="caret-down" theme="filled" />}
                    showArrow
                    className="story-filters-content_type-select"
                  >
                    {getFilteredByCompany(
                      filters.get("content_types").select_options
                    ).map(({ value, label }) => (
                      <Option value={value} key={value}>
                        {label}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("date", {
                  initialValue: filtersInitialValues.date
                })(
                  <RangePicker
                    className="custom-filter-date-picker"
                    placeholder={["Date from", "Date to"]}
                    suffixIcon={<Icon type="caret-down" theme="filled" />}
                    showArrow
                  />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("status", {
                  initialValue: filtersInitialValues.status
                })(
                  <Select
                    size="large"
                    dropdownMatchSelectWidth={false}
                    mode="multiple"
                    placeholder={filters.get("statuses").name}
                    suffixIcon={<Icon type="caret-down" theme="filled" />}
                    showArrow
                    className="story-filters-status-select"
                  >
                    {getFilteredByCompany(
                      filters.get("statuses").select_options
                    ).map(({ value, label }) => (
                      <Option value={value} key={value}>
                        {label}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("links", {
                  initialValue: filtersInitialValues.links
                })(
                  <Select
                    size="large"
                    dropdownMatchSelectWidth={false}
                    mode="multiple"
                    placeholder="Linked Story"
                    suffixIcon={<Icon type="caret-down" theme="filled" />}
                    showArrow
                    className="story-filters-linked_stories-select"
                  >
                    {filters
                      .get("links")
                      .select_options.map(({ value, label }) => (
                        <Option value={value} key={value}>
                          {label}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("searchCommon", {
                  initialValue: filtersInitialValues.searchCommon
                })(
                  <Input
                    placeholder="Story Name, Note, Brief, Creator"
                    prefix={<Icon component={iconSearch} />}
                    size="large"
                    className="search-input common-search"
                  />
                )}
              </Form.Item>
            </div>
          </Form>
          {isAbleToCreateStory && (
            <Button
              text="New Story"
              icon="add"
              className="story-list-add-button"
              htmlType="link"
              pathname="/create-story"
              from={location.pathname}
            />
          )}
        </div>
      )}
    </React.Fragment>
  );
}

const searchEngine = (props, changedFields, allFields) => {
  const { history } = props;
  // 1-create object, in which we will add only necessary filters later
  const usedFilters = {};
  // 2-get previous url params from url (including sorting, pagination, limit)
  const urlParams = getUrlParams();
  // 3-iterate over all fields, delete from urlParams fields with old filters, create & set fields only for applied filters
  Object.keys(allFields).forEach(fieldName => {
    delete urlParams[fieldName];
    switch (fieldName) {
      case "searchCommon":
        if (
          allFields[fieldName].value &&
          allFields[fieldName].value.length !== 0
        ) {
          usedFilters[fieldName] = allFields[fieldName].value;
        }
        break;
      case "category":
        delete urlParams.category;
        if (
          allFields[fieldName].value &&
          allFields[fieldName].value.length !== 0
        ) {
          usedFilters[fieldName] = allFields[fieldName].value;
        }
        break;
      case "date":
        delete urlParams.dateFrom;
        delete urlParams.dateTo;
        if (
          allFields[fieldName].value &&
          allFields[fieldName].value.length !== 0
        ) {
          usedFilters.dateFrom = allFields[fieldName].value[0].format(
            "YYYY-MM-DD"
          );
          usedFilters.dateTo = allFields[fieldName].value[1].format(
            "YYYY-MM-DD"
          );
        }
        break;
      default:
        if (
          allFields[fieldName].value &&
          allFields[fieldName].value.length !== 0
        ) {
          usedFilters[fieldName] = allFields[fieldName].value.join(",");
        }
        break;
    }
  });

  history.push(
    createSearchString({
      ...usedFilters,
      ...urlParams,
      page: 1
    })
  );
};

const debouncedSearchEngine = debounce(1000, searchEngine);

const formFieldChangeHandler = (...args) => {
  const changedFieldNames = Object.keys(args[1]);

  if (changedFieldNames.includes("searchCommon")) {
    debouncedSearchEngine(...args);
    return;
  }

  searchEngine(...args);
};

export default Form.create({ onFieldsChange: formFieldChangeHandler })(
  observer(StoryListFilters)
);
